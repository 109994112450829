import React from "react"

const ScreenFour = () => {
    return (
        <>
        <div id="screen-4">
            <h1 className="quote" style={{
                width: '65%',
                margin: '0 auto',
                letterSpacing: '1px',
            }}>
                &ldquo;...think of the sounds that <br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    went into the creation of <br/>
                    each of the stories <br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    you encounter.&rdquo;
            </h1>
            <p style={{
                marginTop: '0',
                marginBottom: '0',
            }}>
                |
            </p>
            <p className="co-editor"> 
                Charis Loke, Co-editor of<br/>
                <em>SOUND: A Comics Anthology</em>
            </p>
            <div id="right-circle">
                <h4>What did your sound look like to you?</h4>
                <p>
                Please share your art on social media with <span id="tag">#soundcomics</span> and tag <span id="tag">@differenceenginesg</span>
                </p>
            </div>
            <div id="bottom-text">
            <p>
                Like the creators, you would have crafted a story
                or piece of art that is unique and personal to you.
                <br/><br/>
                Join us as we showcase the stories behind the
                thirteen comics, and hear from the writers and
                illustrators who made them happen.
            </p>
            </div>
            
        </div>
        </>
    );
}

/*<div className="scroll-footer">
                Scroll down
            </div>*/

export default ScreenFour