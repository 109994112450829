import React from "react"
import CanvasApp from "./canvas/canvas"

const CanvasScreen = () => {
    return (
        <>
        <div id="canvas">
            <br/><br/>
            <p style={{textAlign:'center', fontSize: '1.125rem', marginBottom: '0'}}>What does sound look like to you?</p>
            <CanvasApp/>
            
        </div>
        </>
    );
}

/*<div className="scroll-footer">
                Scroll down
            </div>*/

export default CanvasScreen;
