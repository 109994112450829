//import { fabric } from "fabric"
const fabric = typeof window !== `undefined` ? require("fabric").fabric : null;

/* This is a command pattern that will keep track of all the user actions on the canvas.
 * It takes into account adding, modifying and removing the object.
 * However, since this canvas is only concerned with adding objects on fabric.js canvas
 * we will only add an event listener for that */

class CommandHistory {
    commands = [];
    index = 0;
    isRedo = false;
    getIndex() {
        return this.index;
    }
    back() {
        console.log('undo');
        if (this.index > 0) {
            console.log(this.index);
            let command = this.commands[--this.index];
            command.undo();
            //this.index = this.index - 1;
        }
        return this;
    }
    forward() {
        console.log('redo');
        this.isRedo = true;
        if (this.index < this.commands.length) {
            let command = this.commands[this.index++];
            command.execute();
        }
        this.isRedo = false;
        return this;
    }
    add(command) {
        /* it's important to check if the command is a redo
         * because if it is, it will add the brush stroke to the screen again
         * and this will increment the index and screw up the command history
         */
        if(this.isRedo) return this;

        if (this.commands.length) {
            this.commands.splice(this.index, this.commands.length - this.index);
        }
        console.log("adding command...");
        this.commands.push(command);
        this.index++;
        return this;
    }
    clear() {
        this.commands.length = 0;
        this.index = 0;
        return this;
    }
}

// use when you init your Canvas, like this.history = new CommandHistory();

class AddCommand {
    constructor(receiver, controller) {
        this.receiver = receiver;
        this.controller = controller;
        this.type = "add";
    }
    execute() {
        this.controller.add(this.receiver);
    }
    undo() {
        this.controller.remove(this.receiver);
    }
}

// When you will add object on your canvas invoke also this.history.add(new AddCommand(object, controller))

class RemoveCommand {
    constructor(receiver, controller) {
        this.receiver = receiver;
        this.controller = controller;
        this.type = "remove";
    }
    execute() {
        this.controller.add(this.receiver);
    }
    undo() {
        this.controller.remove(this.receiver);
    }
}


class TransformCommand {
    constructor(receiver, options = {}) {
        this.receiver = receiver;
        this._initStateProperties(options);
        
        this.state = {};
        this.prevState = {};
        
        this._saveState();
        this._savePrevState();
    }
    execute() {
        this._restoreState();
        this.receiver.setCoords();
    }
    undo() {
        this._restorePrevState();
        this.receiver.setCoords();
    }
    // private
    _initStateProperties(options) {
        this.stateProperties = this.receiver.stateProperties;
        if (options.stateProperties && options.stateProperties.length) {
            this.stateProperties.push(...options.stateProperties);
        }
    }
    _restoreState() {
        this._restore(this.state);
    }
    _restorePrevState() {
        this._restore(this.prevState);
    }
    _restore(state) {
        this.stateProperties.forEach((prop) => {
            this.receiver.set(prop, state[prop]);
        });
    }
    _saveState() {
        this.stateProperties.forEach((prop) => {
            this.state[prop] = this.receiver.get(prop);
        });
    }
    _savePrevState() {
        if (this.receiver._stateProperties) {
            this.stateProperties.forEach((prop) => {
                this.prevState[prop] = this.receiver._stateProperties[prop];
            });
        }
    }
}

function addHistoryToCanvas() {
    fabric.Object.prototype.selectable = false;
    fabric.Canvas.prototype.historyInit = function () {
        this.history = new CommandHistory();

        this.clearCanvas = {
            justCleared: false,
        };
        
        this.on('object:added', function(e) {
            //console.log(this);
            console.log(this.freeDrawingBrush);
            if(this.clearCanvas.justCleared) {
                this.clearCanvas.justCleared = false;
            }

            this.history.add(new AddCommand(e.target, this));
        })
        
        this.on('object:removed', function(e) {
            //this.history.add(new RemoveCommand(e.target, this));
        })
        
        this.on('object:modified', function(e) {
            this.history.add(new TransformCommand(e.target, this));
        })
        
    }
}

export { addHistoryToCanvas }