import React from "react"

//import bookImg from "../../assets/buy/book_image.png"

import FinalFooter from "./footer"

import fb from "../../assets/buy/fb.png"
import ig from "../../assets/buy/ig.png"
import twitter from "../../assets/buy/twitter.png"
import email from "../../assets/buy/email.png"

const ScreenEight = () => {
    return (
        <>
        <div id="about-de"/>
        <div id="screen-8">
            <div className="final-top">
                <h4>
                    Difference Engine is an independent
                    comics publisher based in Singapore.
                </h4>
                <br/>
                <p>
                    We are inspired by stories from Asia and are committed to publishing diverse,
                    well-written, and beautifully illustrated comics of all genres, both print and digital.
                    We would love to work with writers and illustrators, both new and experienced,
                    to contribute to the growing Southeast Asian comics community.
                </p>
                <p style={{margin: '1rem 0'}}>
                    If you have a story to tell, we want to hear from you!
                </p>
                <p>
                    Find us at www.differenceengine.sg <br/>
                    <span className="text-center center-block" id="social-media">
                        <a href="https://www.facebook.com/differenceenginesg"><img src={fb} alt="facebook"/></a>
                        <a href="https://www.instagram.com/differenceenginesg"><img src={ig} alt="instagram"/></a>
                        <a href="https://twitter.com/DEcomics_Sg"><img src={twitter} alt="twitter"/></a>
                        <a href="mailto:readcomics@differenceengine.sg"><img src={email} alt="email"/></a>
                        @differenceengine.sg
                    </span>
                </p>
            </div>
            <div className="credits">
                Audio clips adapted from: 
                Kevin Luce, Gary Bard, Tyops, craigsmith, rileywarren, HerbertBoland, univ_lyon3, TheGloomWorker, Jordan_Costello, PeteBarry, Penumbradupstep, newagesoup, Pfannkuchn and Freesound
            </div>

            <FinalFooter/>
            
        </div>
        </>
    );
}

export default ScreenEight