/* this is where the interactive canvas app is located
 * something has to be done about the command line ESLint errors
 * /no-static-element-interactions can bs solved by adding
 * onKeyPress={onKeyPressHandler}
    role="button"
    tabIndex="0"
 * to divs or elements that contain click handlers
 */

import React, { useRef, useEffect } from "react";
import "./fabric-history"
import {addHistoryToCanvas} from "./fabric-history"

import soundButton from "../../../assets/canvas/icon-speaker.png"
import { canvasUri } from "./canvas-uri"
import brush1 from "../../../assets/canvas/brush1.png"
import brush2 from "../../../assets/canvas/brush2.png"
import brush3 from "../../../assets/canvas/brush3.png"
import eraser from "../../../assets/canvas/eraser.png"

import iconClose from "../../../assets/canvas/icon-close.png"
import iconPencil from "../../../assets/canvas/icon-pencil.png"

import { getSound, handleSounds } from "../sounds"

const fabric = typeof window !== `undefined` ? require("fabric").fabric : null;

let $ = function (id) { return document.getElementById(id) };

const CanvasApp = () => {
    // creates the canvas a side-effect reference
    const canvas = useRef(null);

    function vh(v) {
        var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        return (v * h) / 100;
    }

    function vw(v) {
        var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        return (v * w) / 100;
    }

    //gets the current screen rotation
    let isLandscape = true;

    let toolsContainer;

    /*get the sound text*/
    let soundText;

    /*get a list of all the colors and then map it to the toolbox*/
    const colors = ['#002677', '#0032b9', '#91bfee', '#bad6f6', '#e10098', '#ff41b9', '#ff99ff', '#ffb4e6'];

    /*save the current color so you can return to it when switching brushes*/
    let currentColor = '#002677';
    let currentBrushID = 'pencil';

    let squarePatternBrush;
    
    const toolBox = (
        <div className="toolbox" id="toolbox">
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan={2} id="clear-canvas" onClick={handleClick}>
                                        Clear All
                                    </td>
                                </tr>
                                <tr>
                                    <td id="undo-canvas" onClick={handleClick} className="undo-redo-canvas"
                                        style ={{
                                            borderRight: 'solid 1px grey',
                                        }}>
                                        Undo
                                </td>
                                    <td id="redo-canvas" onClick={handleClick} className="undo-redo-canvas">
                                        Redo
                                </td>
                                </tr>
                                <tr><td colSpan={2}>
                                    Brushes
                                <br />
                                    <div className="brush" id="pencil" onClick={() => handleBrush('pencil')}>
                                        <div className="brush-container"></div>
                                        <img src={brush1} alt="paint-brush" />
                                    </div>
                                    <div className="brush" id="spray" onClick={() => handleBrush('spray')}>
                                        <div className="brush-container"></div>
                                        <img src={brush2} alt="paint-brush" />
                                    </div>
                                    <div className="brush" id="square" onClick={() => handleBrush('square')}>
                                        <div className="brush-container"></div>
                                        <img src={brush3} alt="paint-brush" />
                                    </div>
                                    <br />
                                    <input type="range" min="1" max="32" defaultValue="16" id="drawing-line-width" onChange={handleChange} />
                                </td></tr>
                                <tr><td colSpan={2} style={{paddingBottom: '2px'}}>
                                    Colour
                                <br />
                                    {colors.map((value, index) => {
                                        return (
                                            <div key={value} className="colour-selection" id={value} style={{
                                                backgroundColor: value,
                                            }} onClick={() => handleColor(value)} />
                                        );
                                    })}
                                </td></tr>
                                <tr><td colSpan={2}>
                                    Eraser
                                <br />
                                    <div className="eraser-container" id="small-eraser" onClick={() => handleBrush('small-eraser')}>
                                        <img src={eraser} className="eraser" style={{
                                            width: 'auto',
                                            height: '30%',
                                            position: 'absolute',
                                            top: '35%',
                                            left: '0',
                                            right: '0',
                                            bottom: '0',
                                        }} />
                                    </div>
                                    <div className="eraser-container" id="medium-eraser" onClick={() => handleBrush('medium-eraser')}>
                                        <img src={eraser} className="eraser" style={{
                                            width: 'auto',
                                            height: '50%',
                                            position: 'absolute',
                                            top: '25%',
                                            left: '0',
                                            right: '0',
                                            bottom: '0',
                                        }} />
                                    </div>
                                    <div className="eraser-container" id="large-eraser" onClick={() => handleBrush('large-eraser')}>
                                        <img src={eraser} className="eraser" style={{
                                            width: 'auto',
                                            height: '80%',
                                            position: 'absolute',
                                            top: '10%',
                                            left: '0',
                                            right: '0',
                                            bottom: '0',
                                        }} />
                                    </div>
                                </td></tr>
                            </tbody>
                        </table>
                    </div>
    );
    //if(window.innerWidth < 640) console.log('hello');
    //console.log('100vh: ' + vh(100));

    function handleResize(e) {
        if ($('my-fabric-canvas') == null || canvas.current == undefined) return;
        /*
        if(window.innerWidth < 850) {
            document.getElementById('tools-container').style.display = 'none';
        } else {
            document.getElementById('tools-container').style.display = 'block';
        }
        */
        if((window.matchMedia("(orientation: landscape)").matches && !isLandscape) || 
                (window.matchMedia("(orientation: portrait)").matches && isLandscape)) {
            window.location.reload(false);
        }

        // the canvas seems to resize automatically on portrait devices
        // so this line prevents it from doing so
        if(!isLandscape) return;

        const ratio = canvas.current.getWidth() / canvas.current.getHeight();
        const scale = vw(60) / canvas.current.getWidth();
        const zoom = canvas.current.getZoom() * scale;

        canvas.current.setDimensions({ width: vw(60), height: vh(65)});
        canvas.current.setViewportTransform([zoom, 0, 0, 1, 0, 0]);
    }

    function mobileToolsClick(e) {
        $('mobileTools').classList.toggle('change');
        if($('toolbox').style.display === 'none') {
            $('toolbox').style.display = 'block';
            $('pencil-icon').src = iconClose;
            $('pencil-icon').style.marginLeft = '0';
        }
        else {
            $('toolbox').style.display = 'none';
            $('pencil-icon').src = iconPencil;
            $('pencil-icon').style.marginLeft = '5px';
        }
    }

    /*<div className="bar1"></div>
    <div className="bar2"></div>
    <div className="bar3"></div>    */

    if (typeof window !== "undefined") {
        if(window.innerWidth < 640) {
            toolsContainer = (
                <>
                <div className="tools-container-mobile" style={{
                    bottom: '6vh',
                    position: 'absolute',
                    width: '86vw',
                    display: 'flex',
                }}>
                    <div style={{
                        width: '60vw',
                        backgroundColor: '#002677',
                        padding: '5px',
                        borderRadius: '1rem',
                        textAlign: 'left',
                        display: 'flex',
                    }}>
                        <img src={soundButton} onClick={handleSound} id="sound-button2" className="sound-button" style={{
                            marginBottom: '0',
                        }}/>
                        <p id="ear-text"
                            style={{
                                display: 'inline-flex', 
                                marginLeft: '0.5rem',
                                marginBottom: '0', 
                                fontSize: '16px'}}>
                                    "{soundText}"
                        </p>    
                    </div>
                    <div style={{
                        width: '26vw',
                    }}>
                        <div onClick={mobileToolsClick} id="mobileTools" style={{
                            width: '50px',
                            margin: '0 auto',
                            height: '50px',
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            display: 'inline-block',
                            paddingTop: '5px',
                        }}>
                            <img src={iconPencil} id="pencil-icon" style={{
                                width:'40px',
                                objectPosition: 'center',
                                objectFit: 'contain',
                                marginBottom: '0',
                                marginLeft: '5px',
                            }}/>
                        </div>   
                    </div>   
                </div>
                {toolBox}
                </>
            );
        } else {
            toolsContainer = (
                <div className="tools-container" id="tools-container">
                    <div className="top-buttons">
                        <img src={soundButton} id="sound-button2" className="sound-button" onClick={handleSound} />
                        <p id="ear-text" style={{display:'none'}}>"{soundText}"</p>
                    </div>
                    {toolBox}
                    <br />

                </div>
            );
        }
    }

    useEffect(() => {

        if(window.matchMedia("(orientation: landscape)").matches) {
            isLandscape = true;
        } else if(window.matchMedia("(orientation: portrait)").matches) {
            isLandscape = false;
        }
        console.log("isLandscape: " + isLandscape);
        addHistoryToCanvas();
        
        /*make visible the current selection*/
        $(currentBrushID).style.borderRadius = '10px';
        $(currentBrushID).style.border = '1px solid white';

        $(currentColor).style.borderRadius = '10px';
        $(currentColor).style.border = '1px solid white';

        /*make the description text not visible*/
        $('ear-text').style.display = 'none';
        $('ear-text').innerHTML = getSound().description;

        /*what happens when the user resizes the window*/
        window.addEventListener('resize', e => handleResize(e));

        /*initialize the canvas*/
        canvas.current = new fabric.Canvas("my-fabric-canvas");
        
        /*initialize the square pattern brush*/
        squarePatternBrush = new fabric.PatternBrush(canvas.current);
        
        squarePatternBrush.getPatternSrc = function() {

            var squareWidth = 6, squareDistance = 2;
        
            var patternCanvas = document.createElement('canvas');
            patternCanvas.width = patternCanvas.height = squareWidth + squareDistance;
            var ctx = patternCanvas.getContext('2d');
        
            ctx.fillStyle = this.color;
            ctx.fillRect(0, 0, squareWidth, squareWidth);
        
            return patternCanvas;
        };

        if(window.innerWidth < 640) {
            $('toolbox').style.display = 'none';
            canvas.current.setDimensions({ width: vw(86), height: vh(70) });
        } else {
            canvas.current.setDimensions({ width: vw(60), height: vh(65) });
        }
        //imageSmoothingEnabled = false

        fabric.Image.fromURL(canvasUri, function (bgImg) {
            if(window.innerWidth < 640) {
                bgImg.scaleToWidth(vw(86));
                bgImg.scaleToWidth(vh(75));
            } else {
                bgImg.scaleToWidth(vw(60));
                bgImg.scaleToHeight(vh(65));
            }

            // add background image
            canvas.current.setBackgroundImage(bgImg, canvas.current.renderAll.bind(canvas.current), {
                originX: 'left',
                originY: 'top',
                scaleX: canvas.current.width / bgImg.width,
                scaleY: canvas.current.height / bgImg.height
            }, {
                crossOrigin: 'anonymous',
            });
        });

        /*placeholder textbox
        const textbox = new fabric.Textbox("Click on the Rectangle to move it.", {
            fontSize: 20,
            left: 50,
            top: 100,
            width: 200
        });*/
        
        /* initialize the current brush*/
        canvas.current.isDrawingMode = true;
        canvas.current.freeDrawingBrush.globalCompositeOperation = 'source-over';
        canvas.current.freeDrawingBrush.color = currentColor;
        canvas.current.freeDrawingBrush.width = 16;

        

        /* this code creates the borders for the freeDrawingBrush*/
        canvas.current.defaultCursor = 'crosshair';
        canvas.current.moveCursor = 'crosshair';
        canvas.current.selection = false;
        canvas.current.on('mouse:down:before', function(options) {
            console.log(options.e.layerX, options.e.layerY);
            if( (options.e.layerX < 20 || options.e.layerX > canvas.current.width - 20) ||
                (options.e.layerY < 25 || options.e.layerY > canvas.current.height - 25) ) {
                canvas.current.isDrawingMode = false;
            }
            else {
                canvas.current.isDrawingMode = true;
            }
        });
        
        canvas.current.on('mouse:move:before', function(options) {
            if( (options.e.layerX < 20 || options.e.layerX > canvas.current.width - 20) ||
                (options.e.layerY < 25 || options.e.layerY > canvas.current.height - 25) ) {
                    if(canvas.current._isCurrentlyDrawing) {
                        canvas.current.oldEnd = undefined;
                        canvas.current.freeDrawingBrush._finalizeAndAddPath();
                        canvas.current.freeDrawingBrush.onMouseUp(options);
                    }
                    canvas.current._isCurrentlyDrawing = false;
                    canvas.current.isDrawingMode = false;
            }
        });

        // create the undo state
        canvas.current.historyInit();

        // UseEffect's cleanup function
        return () => {
            canvas.current.dispose();
        };
    }, []);

    function setBrushProperties() {
        canvas.current.freeDrawingBrush.globalCompositeOperation = 'source-over';
        //canvas.current.freeDrawingBrush.color = $('drawing-color').value;
        canvas.current.freeDrawingBrush.width = parseInt($('drawing-line-width').value, 10) || 1;
                
    }

    function convertToImage() {
        //document.getElementById('canvas-img').src = canvas.current.toDataURL('png', 1);
        var objects = canvas.current.getObjects('path');
        for (let i in objects) {
            if (objects[i].id === "eraser") {
                //objects[i].opacity = 0;
                objects[i].color = 'rgba(0,0,0,0)';
            }
        }

        var gh = canvas.current.toDataURL({
            format: 'png',
            multiplier: 5,
            quality: 1
        });

        var a = document.createElement('a');
        a.href = gh;
        a.download = 'image.png';

        a.click();
        canvas.current.renderAll();
    }

    /*change in width or color*/
    function handleChange(e) {
        switch (e.target.id) {
            case "drawing-line-width":
                canvas.current.freeDrawingBrush.width = parseInt(e.target.value, 10) || 1;
                //$('thickness-value').innerHTML = e.target.value < 10 ? '0' + e.target.value : e.target.value;
                break;
            /*deprecated*/
            case "drawing-color":
                canvas.current.freeDrawingBrush.color = e.target.value;
                break;
        }
    }

    /*when the user changes color*/
    function handleColor(id) {
        $(currentColor).style.borderRadius = '0';
        $(currentColor).style.border = '0px';
        $(id).style.borderRadius = '10px';
        $(id).style.border = '1px solid white';
        canvas.current.freeDrawingBrush.color = id;
        currentColor = id;
    }

    /*undo or clear*/
    function handleClick(e) {
        switch (e.target.id) {
            case "clear-canvas":
                canvas.current.clearCanvas = {
                    justCleared: true,
                    canvasJSON: JSON.stringify(canvas.current),
                };
                canvas.current.clear();
                /* resets the background img */
                fabric.Image.fromURL(canvasUri, function (bgImg) {
                    bgImg.scaleToWidth(640);
                    bgImg.scaleToHeight(480);
                    // add background image
                    canvas.current.setBackgroundImage(bgImg, canvas.current.renderAll.bind(canvas.current), {
                        originX: 'left',
                        originY: 'top',
                        scaleX: canvas.current.width / bgImg.width,
                        scaleY: canvas.current.height / bgImg.height
                    }, {
                        crossOrigin: 'anonymous',
                    });
                });

                break;
            case "undo-canvas":
                if(canvas.current.clearCanvas.justCleared) {
                    canvas.current.loadFromJSON(canvas.current.clearCanvas.canvasJSON);
                    canvas.current.renderAll();
                    canvas.current.clearCanvas.justCleared = false;
                }
                canvas.current.history.back();
                break;
            case "redo-canvas":
                canvas.current.history.forward();
                break;
            case "ear":
                if ($('ear-wrapper').style.backgroundColor == 'rgba(15, 0, 185, 0)') {
                    $('ear-wrapper').style.backgroundColor = 'rgba(15,0,185,1)';
                } else {
                    $('ear-wrapper').style.backgroundColor = 'rgba(15,0,185,0)';
                }

                if ($('ear-wrapper').style.color == 'rgba(255, 255, 255, 0)') {
                    $('ear-wrapper').style.color = 'rgba(255,255,255,1)';
                } else {
                    $('ear-wrapper').style.color = 'rgba(255,255,255,0)';
                }
                break;
        }
    }



    /*selecting a new brush or an eraser*/
    function handleBrush(id) {
        $(currentBrushID).style.borderRadius = '0';
        $(currentBrushID).style.border = '0px';
        $(id).style.borderRadius = '10px';
        $(id).style.border = '1px solid white';
        currentBrushID = id;
        switch (id) {
            case "pencil":
                canvas.current.freeDrawingBrush = new fabric.PencilBrush(canvas.current);
                canvas.current.freeDrawingBrush.color = currentColor;
                break;
            case "spray":
                canvas.current.freeDrawingBrush = new fabric.SprayBrush(canvas.current);
                canvas.current.freeDrawingBrush.color = currentColor;
                break;
            case "square":
                canvas.current.freeDrawingBrush = squarePatternBrush;
                //canvas.current.freeDrawingBrush = new fabric.PatternBrush(canvas.current);
                canvas.current.freeDrawingBrush.color = currentColor;
                canvas.current.freeDrawingBrush.width = parseInt($('drawing-line-width').value, 10) || 1;
                break;
            case "small-eraser":
                canvas.current.freeDrawingBrush = new fabric.PencilBrush(canvas.current);
                canvas.current.freeDrawingBrush.color = 'rgba(255,255,255,1)';
                canvas.current.freeDrawingBrush.width = 8;
                return;
            case "medium-eraser":
                canvas.current.freeDrawingBrush = new fabric.PencilBrush(canvas.current);
                canvas.current.freeDrawingBrush.color = 'rgba(255,255,255,1)';
                canvas.current.freeDrawingBrush.width = 15;
                return;
            case "large-eraser":
                canvas.current.freeDrawingBrush = new fabric.PencilBrush(canvas.current);
                canvas.current.freeDrawingBrush.color = 'rgba(255,255,255,1)';
                canvas.current.freeDrawingBrush.width = 28;
                return;
        }
        setBrushProperties();
    }

    /*play the sound and listen for when it ends*/
    function handleSound() {
        handleSounds();
    }

    const renderCanvas = typeof window === `undefined` ? (<div className="canvasApp"></div>) : (
        <div className="canvasApp">
            {toolsContainer}
            <div className="paper-container" id="paper-container">
                <canvas id="my-fabric-canvas" />
            </div>
            <div className="canvas-footer">
                <div id="no-rotate">
                    If you are using a mobile device, please do not rotate the screen.
                </div>
                <button id="download-img" onClick={convertToImage}>Download</button>
                <p>
                Click here to download and save your artwork!
                </p>
            </div>
        </div>
    );

    return renderCanvas;
}

export default CanvasApp;