import React from "react"

import logo from "../../assets/logo2.png";

const ScreenOne = () => {
    return (
        <>
        <div id="screen-1">
            <div id="navbar">
                <nav className="navbar" style={{backgroundColor: 'rgba(0,0,0,0)'}}>
                <div/>
                <div/>
                <a className="navbar-brand" href="https://differenceengine.sg/comics/sound-a-comics-anthology/" target="_blank">
                    <button type="button" className="navbar-button">Buy the comic</button>
                </a>
                </nav>
            </div>
            <div id='best-viewed' style={{}}>
                This website is best viewed on desktop.
            </div>
            <img src={logo} width="200" height="150" alt="logo"/>
            <div className="text-wrapper">
                <h2>What is <em>SOUND</em>?</h2>
                <p>
                    Writers and illustrators from all around 
                    <span style={{color:'rgb(255,65,185)'}}> Southeast Asia </span>
                    were challenged to push the boundaries of depicting the unseen
                    through the medium of visuals and words, while telling stories
                    important to them.
                </p>
                <p style={{marginTop: '3vh'}}>
                    Now you can be part of that experience.
                </p>
                <h3>
                    Are you ready?
                </h3>
            </div>
        </div>
        </>
    );
}

export default ScreenOne