import whispersOfTheEarth from "../../assets/audio/wind-blowing.wav"
import aCatWhoBarked from "../../assets/audio/dogs-barking.wav"
import aCall from "../../assets/audio/a call.wav"
import bonusPoints from "../../assets/audio/bonus points.wav"
import folk from "../../assets/audio/folk-ph music.wav"
import hokkienForBeginners from "../../assets/audio/hokkien for beginners.wav"
import onOff from "../../assets/audio/on-off.wav"
import outOfSight from "../../assets/audio/out of sight-submerged.mp3"
import roadTripRadio from "../../assets/audio/road trip radio.mp3"
import signals from "../../assets/audio/signals.wav"
import voicesAndVotes from "../../assets/audio/voices_votes.wav"
import blabber from "../../assets/audio/blabber blaster sounds.wav"
import softlySayang from "../../assets/audio/softly-sayang.mp3"

let whispersSound;
let aCatWhoBarkedSound;
let aCallSound;
let bonusPointsSound;
let folkSound;
let hokkienForBeginnersSound;
let onOffSound;
let outOfSightSound;
let roadTripRadioSound;
let signalsSound;
let voicesAndVotesSound;
let blabberSound;
let softlySayangSound;

let sounds = [];
let currentSound;

function createSounds() {
    whispersSound = {
        sound: new Audio(whispersOfTheEarth),
        description: 'Wind howling and the earth splitting.',
    }

    aCatWhoBarkedSound = {
        sound: new Audio(aCatWhoBarked),
        description: 'People chat and dogs bark as cars zoom past.',
    }

    aCallSound = {
        sound: new Audio(aCall),
        description: 'A faraway chant.',
    }

    bonusPointsSound = {
        sound: new Audio(bonusPoints),
        description: 'A typical eighties video game soundtrack.',
    }

    folkSound = {
        sound: new Audio(folk),
        description: 'Women sing to a tribal drum beat.',
    }

    hokkienForBeginnersSound = {
        sound: new Audio(hokkienForBeginners),
        description: 'A man flicks between Mandarin Chinese and Taiwanese dialects seamlessly.',
    }

    onOffSound = {
        sound: new Audio(onOff),
        description: 'An abstract, electronic tune.',
    }

    outOfSightSound = {
        sound: new Audio(outOfSight),
        description: 'Submerging your head underwater.',
    }

    roadTripRadioSound = {
        sound: new Audio(roadTripRadio),
        description: 'Tuning into the radio.',
    }

    signalsSound = {
        sound: new Audio(signals),
        description: 'An emergency siren.',
    }

    voicesAndVotesSound = {
        sound: new Audio(voicesAndVotes),
        description: 'A crowded procession.',
    }

    blabberSound = {
        sound: new Audio(blabber),
        description: 'A laser blaster.',
    }

    softlySayangSound = {
        sound: new Audio(softlySayang),
        description: 'A lady sings quietly to a child.',
    }

    sounds = [  folkSound, 
                whispersSound, 
                hokkienForBeginnersSound, 
                bonusPointsSound, 
                voicesAndVotesSound,
                onOffSound,
                outOfSightSound,
                aCatWhoBarkedSound,
                blabberSound,
                softlySayangSound, 
                roadTripRadioSound,
                signalsSound,
                aCallSound
            ];
    //currentSound = Math.floor(Math.random() * sounds.length);
    currentSound = 0;
}

function getSound() {
    return sounds[currentSound];
}

function pauseSound() {
    sounds[currentSound].sound.pause();
    sounds[currentSound].currentTime = 0;
    currentSound += 1;
    if(currentSound === sounds.length) {
        currentSound = 0;
    }
}

let $ = function (id) { return document.getElementById(id) };

function handleSounds() {
    if(!getSound().sound.paused) {
        pauseSound();
        $('sound-button').style.backgroundColor = 'white';
        $('sound-button2').style.backgroundColor = 'white';
        $('ear-text').style.display = 'none';
        return;
    }
    getSound().sound.play();
    $('sound-button').style.backgroundColor = 'rgb(255, 65, 185)';

    $('ear-text').innerHTML = getSound().description;
    $('ear-text').style.display = window.innerWidth < 640 ? 'inline-block' : 'block';
    $('sound-button2').style.backgroundColor = 'rgb(255, 65, 185)';
    getSound().sound.addEventListener('ended', function() {
        $('sound-button').style.backgroundColor = 'white';

        $('ear-text').style.display = 'none';
        $('sound-button2').style.backgroundColor = 'white';
    });
}

export { createSounds, getSound, pauseSound, handleSounds };