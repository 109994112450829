import React, { useEffect, useRef } from "react"

import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"

import ScreenOne from "../components/screens/screen-1"
import ScreenTwo from "../components/screens/screen-2"
import CanvasScreen from "../components/screens/screen-3"
import ScreenFour from "../components/screens/screen-4"
import ScreenFive from "../components/screens/screen-5"
import ScreenSix from "../components/screens/screen-6"
//import ScreenSeven from "../components/screens/screen-7"
import ScreenEight from "../components/screens/screen-8"
import Navbar from "../components/navbar"
import metaLogo from "../../src/images/metalogo.png"
//import AudioPlayer from "../components/screens/sounds"


//<Navbar static={false}/>
//
//
//
/*
<ScreenTwo/>
    <CanvasScreen/>
    <ScreenFour/>
    <ScreenFive/>
    <ScreenSix/>
    <ScreenEight/>
*/
const IndexPage = () => {

  const privacycookie = useRef(null);

  const storeCookie = () => {
    document.getElementById('privacy-policy').style.display = "none";
    document.cookie = "privacypolicy=true";
  }

  const getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  useEffect(() => {
    privacycookie.current = getCookie('privacypolicy');
    //console.log('privacycookie: ' + privacycookie.current);
    if(privacycookie.current !== "") {
      document.getElementById('privacy-policy').style.display = 'none';
    }
  })

  return (
    <Layout>
      <SEO title="SOUND" image={metaLogo} />
      <Navbar static={false}/>
      <ScreenOne/>
      <ScreenTwo/>
      <CanvasScreen/>
      <ScreenFour/>
      <ScreenFive/>
      <ScreenSix/>
      <ScreenEight/>
      <div id="privacy-policy" style={{
                backgroundColor: 'white',
                color: 'black',
                fontFamily: 'inherit',
                bottom: '0px',
                position: 'fixed',
                display: 'block',
                fontSize: '15px',
                margin: '0 auto',
                padding: '12px 10px',
                textAlign: 'center',
                boxSizing: 'border-box',
                width: '100%',
                zIndex: '9999',
                left: '0',
                fontWeight: '300',
                boxShadow: '0 -1px 10px 0 rgba(172, 171, 171, 0.3)',
            }}>
                <span>
                    <div class="" style={{
                        fontSize: '11pt',
                        lineHeight: '18px',
                        fontWeight: 'bold',
                        marginLeft: '10px',
                        float: 'none',
                        margin: '0 auto',
                        display: 'flex',
                        justifyContent: 'space-between',
                        WebkitBoxAlign: 'center',
                        alignItems: 'center',
                    }}>
                        <div class="" style={{
                            width: '70%',
                            textAlign: 'left',
                        }}>
                            We have updated our Privacy Policy. By continuing to visit this website, you agree to our 
                            <a href="http://differenceengine.sg/privacy" target="_blank" style={{
                                color: '#e10098',
                                cursor: 'pointer',
                            }}> privacy policy </a>
                            and our terms of use.
                        </div>
                        <div class="" style={{
                            marginLeft: '20px',
                            display: 'flex',
                            WebkitBoxAlign: 'center',
                            alignItems: 'center',
                            flexWrap: 'nowrap'
                        }}>
                            <a role="button" id="accept-button" onClick={storeCookie} style={{
                                display: 'inline-block',
                                color: 'white',
                                backgroundColor: 'rgb(225, 0, 152)',
                                marginTop: '5px',
                                marginBottom: '5px',
                                marginLeft: '5px',
                                marginRight: '20px',
                                fontWeight: '400',
                                lineHeight: '1',
                                whiteSpace: 'nowrap',
                                cursor: 'pointer',
                                padding: '9px 12px',
                                textDecoration: 'none',
                            }}>ACCEPT</a>
                        </div>
                    </div>
                </span>
            </div>
        }
    </Layout>
  );
}

export default IndexPage

