import React from "react"
import { useState, useRef } from "react"

import coverimage1 from "../../assets/stories/cover-images/1.jpg";
import coverimage2 from "../../assets/stories/cover-images/2.jpg";
import coverimage3 from "../../assets/stories/cover-images/3.jpg";
import coverimage4 from "../../assets/stories/cover-images/4.jpg";
import coverimage5 from "../../assets/stories/cover-images/5.jpg";
import coverimage6 from "../../assets/stories/cover-images/6.jpg";
import coverimage7 from "../../assets/stories/cover-images/7.jpg";
import coverimage8 from "../../assets/stories/cover-images/8.jpg";
import coverimage9 from "../../assets/stories/cover-images/9.jpg";
import coverimage10 from "../../assets/stories/cover-images/10.jpg";
import coverimage11 from "../../assets/stories/cover-images/11.jpg";
import coverimage12 from "../../assets/stories/cover-images/12.jpg";
import coverimage13 from "../../assets/stories/cover-images/13.jpg";

import coverimageMobile1 from "../../assets/stories/cover-images-mobile/1.png";
import coverimageMobile2 from "../../assets/stories/cover-images-mobile/2.png";
import coverimageMobile3 from "../../assets/stories/cover-images-mobile/3.png";
import coverimageMobile4 from "../../assets/stories/cover-images-mobile/4.png";
import coverimageMobile5 from "../../assets/stories/cover-images-mobile/5.png";
import coverimageMobile6 from "../../assets/stories/cover-images-mobile/6.png";
import coverimageMobile7 from "../../assets/stories/cover-images-mobile/7.png";
import coverimageMobile8 from "../../assets/stories/cover-images-mobile/8.png";
import coverimageMobile9 from "../../assets/stories/cover-images-mobile/9.png";
import coverimageMobile10 from "../../assets/stories/cover-images-mobile/10.png";
import coverimageMobile11 from "../../assets/stories/cover-images-mobile/11.png";
import coverimageMobile12 from "../../assets/stories/cover-images-mobile/12.png";
import coverimageMobile13 from "../../assets/stories/cover-images-mobile/13.png";
import { Link } from "gatsby"

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const coverimages = [coverimage1, 
                    coverimage2, 
                    coverimage3, 
                    coverimage4, 
                    coverimage5, 
                    coverimage6, 
                    coverimage7,
                    coverimage8,
                    coverimage9,
                    coverimage10,
                    coverimage11,
                    coverimage12,
                    coverimage13,]

const StoryLink = props => (
    // insert styles or element here
    <Link to={props.to} onClick={props.clicked}>{props.children}</Link>
)

const StoryIcon = (props) => {
    const [hovering, setHovering] = useState(false);

    function handleHover(e) {
        setHovering(!hovering);
        props.hoverHandler(props.id, props.storyKey);
    }

    return (
        <>
            <div className="story-icon" id={props.id} 
                onMouseEnter={handleHover} 
                onMouseLeave={handleHover}
                style={{ opacity: hovering ? 1 : props.opacity,
                    backgroundColor: hovering ? "rgb(255, 65, 185)" : "rgba(255,255,255,1)",
                }}
            />
        </>
    );;
}

//backgroundImage: hovering ? "url(" + wavyLine + ")" : null,
//backgroundSize: hovering ? "150% 100%" : null,
//backgroundColor: hovering ? "rgba(217,217,214,0)" : "rgba(255,255,255,1)",
const ScreenFive = () => {
    const [opacity, setOpacity] = useState(1);
    const [currentID, setCurrentID] = useState('none');
    const [mobile, setMobile] = useState(false);

    const currentKey = useRef(null);

    /*wait for awhile before setting smooth scrolling*/
    async function allowSmoothScroll() {

        let promise = new Promise((resolve, reject) => {
          setTimeout(() => resolve("done!"), 1000)
        });
      
        let result = await promise; // wait until the promise resolves (*)
      
        document.documentElement.style.scrollBehavior = "smooth";
    }

    if(typeof window !== undefined) {
        allowSmoothScroll();
    }

    React.useEffect(() => {
        currentKey.current = 0;
        if (window.innerWidth < 576) {
            setMobile(true);
        }
    }, []);

    function handleHover(id, key) {
        let value = opacity === 1 ? 0.5 : 1;
        setOpacity(value);
        let idValue = opacity === 1 ? id : 'none';
        setCurrentID(idValue);

        currentKey.current = key;
    }

    function createBottom() {
        switch(currentID) {
            case 'whispers':
                return {__html: ""};
                //return {__html: "<img src=" + whispersTitle + "/>"};
                break;
            case 'none':
                return {__html: "<h3 style='font-size:1.75rem; margin-bottom:.5rem;'>Stories</h3><p>Click on the icons to learn more about the stories in <em>SOUND.</em></p>"}
                break;
            default:
                break;
        }
    }

    /* on mobile the stories will render a carousel instead
     * the react-responsive carousel library causes a slight jerky bug 
     * with infiniteLoop and centerMode when swiping
     * so I will be removing centerMode in order to make the swiping transition smoother.
     * this is issue #334 on https://github.com/leandrowd/react-responsive-carousel/
     * and has yet to be resolved.
     */
    const mobileRender = (
        <div id="stories">
        <div className="stories-top-text">
        <h3 style={{fontSize:'1.75rem'}}>Stories</h3><p>Swipe and click on the images to learn more about the stories in <em>SOUND.</em></p>
        </div>
        <Carousel 
            showThumbs={false}
            showArrows={false} 
            autoPlay={false} 
            infiniteLoop={true} 
            centerMode={false} 
            centerSlidePercentage={80}
            >
            <div>
                <Link to="/stories/folk" />
                <img src={coverimageMobile1} alt="cover1"/>
                <p className="legend">
                    <span className="quote">
                        Folk  
                    </span>
                    <br/>
                    WRITTEN BY Paolo Chikiamco
                    <br/>
                    ILLUSTRATED BY Borg Sinaban 
                </p>
            </div>
            <div>
                <Link to="/stories/whispers" />
                <img src={coverimageMobile2} alt="cover2"/>
                <p className="legend">
                    <span className="quote">
                        The Whispers Of The Earth  
                    </span>
                    <br/>
                    WRITTEN BY Amahl S. Azwar
                    <br/>
                    ILLUSTRATED BY Nadiyah Rizki
                </p>
            </div>
            <div>
                <Link to="/stories/hokkien-for-beginners" />
                <img src={coverimageMobile3} alt="cover3"/>
                <p className="legend">
                    <span className="quote">
                        Hokkien for Beginners
                    </span>
                    <br/>
                    WRITTEN & ILLUSTRATED BY Nicolette Lee
                </p>
            </div>
            <div>
                <Link to="/stories/bonus-points" />
                <img src={coverimageMobile4} alt="cover4"/>
                <p className="legend">
                    <span className="quote">
                        Bonus Points 
                    </span>
                    <br/>
                    WRITTEN & ILLUSTRATED BY Benjamin Chee
                </p>
            </div>
            <div>
                <Link to="/stories/voices-and-votes" />
                <img src={coverimageMobile5} alt="cover5"/>
                <p className="legend">
                    <span className="quote">
                        Voices and Votes  
                    </span>
                    <br/>
                    WRITTEN BY Ruth Chris De Vera
                    <br/>
                    ILLUSTRATED BY Rafael Romeo Magat
                </p>
            </div>
            <div>
                <Link to="/stories/on-off" />
                <img src={coverimageMobile6} alt="cover6"/>
                <p className="legend">
                    <span className="quote">
                        ON/OFF  
                    </span>
                    <br/>
                    WRITTEN BY Paolo Chikiamco
                    <br/>
                    ILLUSTRATED BY Borg Sinaban 
                </p>
            </div>
            <div>
                <Link to="/stories/out-of-sight" />
                <img src={coverimageMobile7} alt="cover7"/>
                <p className="legend">
                    <span className="quote">
                        Out of Sight 
                    </span>
                    <br/>
                    WRITTEN BY Paolo Chikiamco
                    <br/>
                    ILLUSTRATED BY Borg Sinaban 
                </p>
            </div>
            <div>
                <Link to="/stories/the-cat-who-barks" />
                <img src={coverimageMobile8} alt="cover8"/>
                <p className="legend">
                    <span className="quote">
                        The Cat Who Barks  
                    </span>
                    <br/>
                    WRITTEN & ILLUSTRATED BY Bryan Arfiandy
                </p>
            </div>
            <div>
                <Link to="/stories/blabber" />
                <img src={coverimageMobile9} alt="cover9"/>
                <p className="legend">
                    <span className="quote">
                        Blabber 
                    </span>
                    <br/>
                    WRITTEN BY Paolo Chikiamco
                    <br/>
                    ILLUSTRATED BY Borg Sinaban 
                </p>
            </div>
            <div>
                <Link to="/stories/softly-sayang" />
                <img src={coverimageMobile10} alt="cover10"/>
                <p className="legend">
                    <span className="quote">
                        Softly Sayang
                    </span>
                    <br/>
                    WRITTEN BY Paolo Chikiamco
                    <br/>
                    ILLUSTRATED BY Borg Sinaban 
                </p>
            </div>
            <div>
                <Link to="/stories/road-trip-radio" />
                <img src={coverimageMobile11} alt="cover11"/>
                <p className="legend">
                    <span className="quote">
                        Road Trip Radio
                    </span>
                    <br/>
                    WRITTEN BY Paolo Chikiamco
                    <br/>
                    ILLUSTRATED BY Borg Sinaban 
                </p>
            </div>
            <div>
                <Link to="/stories/signals" />
                <img src={coverimageMobile12} alt="cover12"/>
                <p className="legend">
                    <span className="quote">
                        Signals   
                    </span>
                    <br/>
                    WRITTEN BY Paolo Chikiamco
                    <br/>
                    ILLUSTRATED BY Borg Sinaban 
                </p>
            </div>
            <div>
                <Link to="/stories/a-call" />
                <img src={coverimageMobile13} alt="cover13"/>
                <p className="legend">
                    <span className="quote">
                        A Call 
                    </span>
                    <br/>
                    WRITTEN BY Paolo Chikiamco
                    <br/>
                    ILLUSTRATED BY Borg Sinaban 
                </p>
            </div>
        </Carousel>
        </div>
    );

    //style={{backgroundImage: opacity === 0.5 ? "url(" + previewFolk + ")" : null, }}

    //opacity: opacity === 0.5 && currentKey.current == i ? '1' : '0',
    //animation: opacity === 0.5 && currentKey.current == i ? 'fade 1s' : 'none',
                        
    const desktopRender = (
        <>
        <div id="stories">
            {
                coverimages.map(function(image, i) {
                    return (
                        <img src={image} alt="bg" key={i} style={{
                            width: '0',
                            height: '0',
                            opacity: '0',
                        }}/>
                    );
                })
            }
            <div className="stories-background" style={{
                    width: 'auto',
                    height: '95%',
                    backgroundImage: "url(" + coverimages[currentKey.current] + ")",
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    opacity: opacity === 0.5 ? '1' : '0',
                    animation: opacity === 0.5 ? 'fade 1s' : 'none',
                }}>
            </div>

            <div style={{fontSize: '1rem'}} className="stories-text"
                    dangerouslySetInnerHTML={createBottom()}/>
            <div className="stories-container">
                <div className="story-row" >
                    <StoryLink to="/stories/folk">
                        <StoryIcon hoverHandler={handleHover} opacity={opacity} id="whispers" storyKey={0} />
                    </StoryLink>
                    <StoryLink to="/stories/whispers">
                        <StoryIcon hoverHandler={handleHover} opacity={opacity} storyKey={1}/>
                    </StoryLink>
                    <StoryLink to="/stories/hokkien-for-beginners">
                        <StoryIcon hoverHandler={handleHover} opacity={opacity} storyKey={2}/>
                    </StoryLink>
                    <StoryLink to="/stories/bonus-points">
                        <StoryIcon hoverHandler={handleHover} opacity={opacity} storyKey={3}/>
                    </StoryLink>
                </div>
                <div className="story-row" >
                    <StoryLink to="/stories/voices-and-votes">
                        <StoryIcon hoverHandler={handleHover} opacity={opacity} storyKey={4}/>
                    </StoryLink>
                    <StoryLink to="/stories/on-off">
                        <StoryIcon hoverHandler={handleHover} opacity={opacity} storyKey={5}/>
                    </StoryLink>
                    <StoryLink to="/stories/out-of-sight">
                        <StoryIcon hoverHandler={handleHover} opacity={opacity} storyKey={6}/>
                    </StoryLink>
                    <StoryLink to="/stories/the-cat-who-barks">
                        <StoryIcon hoverHandler={handleHover} opacity={opacity} storyKey={7}/>
                    </StoryLink>
                    <StoryLink to="/stories/blabber">
                        <StoryIcon hoverHandler={handleHover} opacity={opacity} storyKey={8}/>
                    </StoryLink>
                </div>
                <div className="story-row" >
                    <StoryLink to="/stories/softly-sayang">
                        <StoryIcon hoverHandler={handleHover} opacity={opacity} storyKey={9}/>
                    </StoryLink>
                    <StoryLink to="/stories/road-trip-radio">
                        <StoryIcon hoverHandler={handleHover} opacity={opacity} storyKey={10}/>
                    </StoryLink>
                    <StoryLink to="/stories/signals">
                        <StoryIcon hoverHandler={handleHover} opacity={opacity} storyKey={11}/>
                    </StoryLink>
                    <StoryLink to="/stories/a-call">
                        <StoryIcon hoverHandler={handleHover} opacity={opacity} storyKey={12}/>
                    </StoryLink>
                </div>
                <br/> <br/> <br/>
                </div>
            
        </div>
        </>
    );

    return (mobile ? mobileRender : desktopRender);
}

/*<div className="scroll-footer">
                Scroll down
            </div>*/


export default ScreenFive