import React, {useEffect, useRef} from "react"

import Navbar from "../navbar"

import soundButton from "../../assets/canvas/icon-speaker.png"

import {createSounds, handleSounds} from "./sounds"

import waterDroplets from "../../assets/water-droplets.png"

let $ = function (id) { return document.getElementById(id) };

const ScreenTwo = () => {

    function handleSound() {
        handleSounds();
    }

    let getTranslateY;
    let initial;


    const handleScroll=(e) => {
        
        //console.log('scrolling...');
        
        if(document.getElementById('screen-1') == null) return;

        const window = e.currentTarget;
        if(window.scrollY > document.getElementById('screen-1').offsetHeight) 
            return;

        let scrolled = initial - (window.pageYOffset/2);
        //console.log('translateY(-' + scrolled + 'px)');
        document.getElementById('screen-2').style.transform = 'translateY(-' + scrolled + 'px)';
        

    }

    function throttle(ms, callback) {
        var timer, lastCall=0;
    
        return function() {
            var now = new Date().getTime(),
                diff = now - lastCall;
            console.log(diff, now, lastCall);
            if (diff >= ms) {
                console.log("Call callback!");
                lastCall = now;
                callback.apply(this, arguments);
            }
        };
    }
    
    useEffect(() => {
        initial = Math.abs(((window.innerHeight/2) - window.pageYOffset));
        if(initial > (window.innerHeight)) initial = window.innerHeight/2;
        getTranslateY = 'translateY(-' + initial + 'px)';
        //console.log(getTranslateY);
        window.addEventListener('scroll', (e) => throttle(30, handleScroll(e)));

        createSounds();
    }, []);    

    //<Navbar static={true} screentwo={true}/>
            

    return (
        <>
        <div id="screen-2" style={{transform: getTranslateY}}>
            <Navbar static={true} screentwo={true}/>
            <div id="water-droplets">
                <img src={waterDroplets} alt="water-droplets"/>
                <img src={soundButton} className="sound-button" id="sound-button" onClick={handleSound}/>
            </div>
            <h2>
                Listen closely.
            </h2>
            
            <p>
            <span style={{fontSize:'1.125rem', fontWeight: 'bold'}}>What do you hear?</span><br/>
            Put your brush to the canvas, let your imagination run free, and
            discover what the anthology writers and illustrators experienced
            as they drew inspiration everyday sounds to craft their stories.
            </p>
            
        </div>
        </>
    );
}

/*<div className="scroll-footer">
                Scroll down
            </div>*/

export default ScreenTwo