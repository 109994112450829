import React from "react"

//import videoCover from "../../assets/video-cover.png"
//import placeholder from "../../assets/placeholder1.png"
import frontCover from "../../assets/front-cover.jpg"
import Video from "../video"

import { Link } from "gatsby"

const ScreenSix = () => {
    return (
        <>
        <div id="about-sound"/>
        <div id="screen-6">
            <h3>Hear from the Editors of the Anthology!</h3>
            <div className="row">
                <div className="col-sm-6">
                    <Video
                    videoSrcURL="https://www.youtube.com/embed/TKD1R92GEq4"
                    />
                    <table style={{marginBottom: '0', marginTop: '0.5rem'}}>
                        <tbody>
                        <tr>
                            <td style={{
                                borderBottom: '0px', 
                                textAlign:'center', 
                                width:'40%', 
                                paddingRight: '0px', 
                                fontFamily: 'trailmade',
                                fontSize: '1.875rem',
                                verticalAlign: 'top',
                                }}>
                                Budjette Tan
                            </td>
                            <td style={{fontSize: '.625rem',lineHeight: 'normal', borderBottom: '0px', paddingLeft: '0px'}}>
                            <strong>Budjette</strong> grew up in a haunted house and was told to sleep early
                            because there were aswang flying outside his bedroom window.
                            These stories inspired him to write the comic book, <em>TRESE</em>, which
                            he co-created with artist Kajo Baldisimo. <em>TRESE</em> will soon be adapted
                            into a Netflix anime series.
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-sm-6">
                    <Video
                    videoSrcURL="https://www.youtube.com/embed/eBG5-rsI8iQ"
                    />
                    <table style={{marginBottom: '0', marginTop: '0.5rem'}}>
                        <tbody>
                        <tr>
                            <td style={{
                                borderBottom: '0px', 
                                textAlign:'center', 
                                width:'40%', 
                                paddingRight: '0px', 
                                fontFamily: 'trailmade',
                                fontSize: '1.875rem',
                                verticalAlign: 'top',
                                }}>
                                Charis Loke
                            </td>
                            <td style={{fontSize: '.625rem',lineHeight: 'normal', borderBottom: '0px', paddingLeft: '0px'}}>
                            <strong>Charis</strong> is an illustrator and educator based in Penang, Malaysia.
                            Her work draws upon literature and visual culture, exploring both
                            real and fictional worlds. She is the Comics & Illustrations Editor at
                            New Naratif and a committee member of Arts-ED, where she works
                            on community-based arts and culture education for youth. 
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <h1 className="quote">
                “ This medley of lovingly crafted comics—fantastical, social realist, and confessional—have plenty more in common than their unifying theme of sound.
                <br/>
                They’re also tales of loss, with characters struggling in the midst of broken families, forgotten traditions, failed belief systems and governments.
                <br/>
                They represent the collective spirit of young Southeast Asia—a generation, caught between a colourful past and an uncertain future, drawing themselves together, finding their voices.
                ”</h1>
            <p style={{
                marginTop: '0',
                marginBottom: '0',
                color: 'rgb(225, 0, 152)',
            }}>
                |
            </p>
            <p className="co-editor" style={{color: 'rgb(225, 0, 152)'}}> 
                Ng Yi-Sheng, author of <em>Lion City</em>
            </p>
            <br/>
            <div className="row">
                <div className="col-sm-6" id="front-cover">
                    <img src={frontCover} alt="front-cover"/>
                </div>
                <div className="col-sm-6">
                    <div className="container-text">
                        <h2>
                            Traffic. A crunchy snack. An argument. What does sound look like to you?
                        </h2>
                        <p>
                        <strong>Difference Engine</strong> is proud to present our first comics anthology, <em>SOUND: A Comics
                        Anthology</em>. Writers and illustrators from all around Southeast Asia were challenged
                        to push the boundaries of depicting the unseen through the medium of visuals and
                        words, while telling stories important to them. <br/><br/>
                        Guest editors&nbsp;
                        <a href="https://www.youtube.com/embed/9zfhAWgyjeY"><span className="underline">Budjette Tan</span></a> and&nbsp;
                        <a href="https://www.youtube.com/embed/FsdNMQOY_3g"><span className="underline">Charis Loke</span></a> have 
                        put together an intriguing list of stories that showcase the creativity of creators across Southeast 
                        Asia—both familiar and new names. In a broad range of genres and topics, this collection of stories is an
                        exciting exploration of what the comics medium is capable of.
                        <br/> <br/>
                        <strong>CREATIVE TEAMS</strong>
                        <br/><br/>
                        <Link to="/stories/folk">
                        <span className="underline">Folk</span>
                        </Link>&nbsp;
                        (Paolo Chikiamco & Borg Sinaban)
                        <br/>
                        <Link to="/stories/whispers">
                        <span className="underline">The Whispers of the Earth</span>
                        </Link>&nbsp;
                        (Amahl S. Azwar & Nadiyah Rizki)
                        <br/>
                        <Link to="/stories/hokkien-for-beginners">
                        <span className="underline">Hokkien for Beginners</span>
                        </Link>&nbsp;
                        (Nicolette Lee)
                        <br/>
                        <Link to="/stories/bonus-points">
                        <span className="underline">Bonus Points</span>
                        </Link>&nbsp;
                        (Benjamin Chee)
                        <br/>
                        <Link to="/stories/voices-and-votes">
                        <span className="underline">Voices and Votes</span>
                        </Link>&nbsp;
                        (Rafael Romeo Magat & Ruth Chris De Vera)
                        <br/>
                        <Link to="/stories/on-off">
                        <span className="underline">On : Off</span>
                        </Link>&nbsp;
                        (Xie Shi Min, Xiao Yan, & Richard Phua)
                        <br/>
                        <Link to="/stories/out-of-sight">
                        <span className="underline">Out of Sight</span>
                        </Link>&nbsp;
                        (Jingwei Wong & Soefara Jafney)
                        <br/>
                        <Link to="/stories/the-cat-who-barks">
                        <span className="underline">The Cat Who Barks</span>
                        </Link>&nbsp;
                        (Bryan Arfiandy)
                        <br/>
                        <Link to="/stories/blabber">
                        <span className="underline">Blabber</span>
                        </Link>&nbsp;
                        (Bonni Rambatan)
                        <br/>
                        <Link to="/stories/softly-sayang">
                        <span className="underline">Softly, Sayang</span>
                        </Link>&nbsp;
                        (Sharon Bong & Leong Yi Zhen)
                        <br/>
                        <Link to="/stories/road-trip-radio">
                        <span className="underline">Road Trip Radio</span>
                        </Link>&nbsp;
                        (Cathlyn Vania)
                        <br/>
                        <Link to="/stories/signals">
                        <span className="underline">Signals</span>
                        </Link>&nbsp;
                        (Farid Nad)
                        <br/>
                        <Link to="/stories/a-call">
                        <span className="underline">A Call</span>
                        </Link>&nbsp;
                        (Nadhir Nor)
                        </p>
                    </div>
                </div>
            </div>
            <div className="bottom-buttons" style={{paddingBottom: '2vh'}}>
                <a href="https://differenceengine.sg/comics/sound-a-comics-anthology/" target="_blank" rel="noreferrer">
                    <div className="navbar-button">
                        Buy the comic
                    </div>
                </a>
                <br/>
                
                <Link to="/download">
                    <div className="navbar-button" style={{marginTop:'1rem'}}>
                        Download freebies
                    </div>
                </Link>
            </div>
        </div>
        </>
    );
}

export default ScreenSix